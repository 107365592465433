<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>W związku z konfliktem zbrojnym w Ukrainie prosimy o pilną pomoc!</h1>
      <p>
        SOS Wioski Dziecięce potrzebują każdej wpłaty na rzecz
        <strong>pomocy opuszczonym i osieroconym dzieciom w Ukrainie</strong>.
        Obecnie aż 16 tys. najmłodszych mieszkańców ogarniętego wojną kraju
        czeka na pilne wsparcie.
      </p>
      <p>
        Prosimy Was wszystkich o nadanie mocy drobnym, lecz licznym gestom i
        dziękujemy za każdą darowiznę!
      </p>
      <p>
        Twoja wpłata trafi bezpośrednio na konto
        <strong>Wiosek Dziecięcych</strong>
      </p>
      <img :src="require('@/assets/img/sos-logo.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div class="text-right">
        <img :src="require('@/assets/img/sos-logo.png')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>W związku z konfliktem zbrojnym w Ukrainie prosimy o pilną pomoc!</h1>
      <p>
        SOS Wioski Dziecięce potrzebują każdej wpłaty na rzecz
        <strong style="color: #ffd500"
          >pomocy opuszczonym i osieroconym dzieciom w Ukrainie</strong
        >. Obecnie aż 16 tys. najmłodszych mieszkańców ogarniętego wojną kraju
        czeka na pilne wsparcie.
      </p>
      <p>
        Prosimy Was wszystkich o nadanie mocy drobnym, lecz licznym gestom i
        dziękujemy za każdą darowiznę!
      </p>
      <p>
        Twoja wpłata trafi bezpośrednio na konto
        <strong>Wiosek Dziecięcych</strong>
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
